import React, { FC, useState, ReactNode } from 'react';
import cn from 'classnames';

import {
  DEFAULT_MEDICAL_IMAGE_HEIGHT,
  MedicalImageInterface,
} from '@/shared/config';
import { useAppDispatch } from '@/shared/hooks';

import { toothModel } from '@/entities/tooth';
import { assetsModel } from '@/entities/assets';
import { useCheckReportSignature } from '@/entities/reports';
import { slicesModel } from '@/entities/slices';

import { Skeleton } from '../Skeleton/Skeleton';
import { Icon } from '../Icon/Icon';
import { Checkbox } from '../Checkbox/Checkbox';

import styles from './MedicalImage.module.scss';

const INITIAL_WIDTH = 100;

export type MedicalImageProps = {
  medicalImageLabel?: ReactNode;

  onRemoveMedicalImage?: (medicalImage: MedicalImageInterface) => void;
  focused?: boolean;
  className?: string;
  medicalImage: MedicalImageInterface;
  toggled?: boolean;
  order?: number;
  canUserDeleteSlices?: boolean;
  toggleSelected?: (medicalImage: MedicalImageInterface) => void;
  draggable?: boolean;
  isImgSelected?: boolean;
};

export const MedicalImage: FC<MedicalImageProps> = (props) => {
  const {
    medicalImageLabel,
    medicalImage,
    onRemoveMedicalImage,
    focused,
    className,
    draggable = false,
    toggleSelected,
    isImgSelected,
    toggled,
    order,
    canUserDeleteSlices,
  } = props;

  const dispatch = useAppDispatch();

  const size = {
    w: INITIAL_WIDTH,
    h: DEFAULT_MEDICAL_IMAGE_HEIGHT,
  };

  const [isImageReady, setIsImageReady] = useState(false);
  const [dragging, setDragging] = useState(false);

  const { checkReportSignature } = useCheckReportSignature();

  const handleDeleteMedicalImage = async (
    e: React.MouseEvent<SVGSVGElement>,
  ) => {
    e.stopPropagation();

    const deleteImage = async () => {
      if (onRemoveMedicalImage) {
        onRemoveMedicalImage(medicalImage);
      }

      if (canUserDeleteSlices) {
        const response = await dispatch(
          assetsModel.thunks.deleteAsset({
            AssetID: medicalImage.assetID,
          }),
        ).unwrap();

        if (response?.Result.case === 'Tooth') {
          dispatch(toothModel.actions.setNewestOne(response?.Result.value));
        }
      }
    };

    if (isImgSelected) {
      checkReportSignature({
        onSignatureChecked: deleteImage,
      });
    } else {
      await deleteImage();
    }
  };

  const handleDragStart = () => {
    dispatch(slicesModel.actions.setDraggedSliceID(medicalImage.assetID));

    setDragging(true);
  };
  const handleDragEnd = () => {
    dispatch(slicesModel.actions.setDraggedSliceID(''));

    setDragging(false);
  };

  return (
    <div
      data-group={`${medicalImage.groupName}`}
      className={cn(styles.container, className, focused && styles.focused)}
      draggable={draggable}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <div
        className={styles.imageContainer}
        style={{ height: DEFAULT_MEDICAL_IMAGE_HEIGHT }}
      >
        {!isImageReady && (
          <Skeleton
            width={toggled ? size.w / 2 : size.w}
            borderRadius="12px"
            height={DEFAULT_MEDICAL_IMAGE_HEIGHT}
          />
        )}

        <img
          id={medicalImage.assetID}
          style={{ height: DEFAULT_MEDICAL_IMAGE_HEIGHT }}
          className={styles.medicalImage}
          onLoad={() => setIsImageReady(true)}
        />

        {(onRemoveMedicalImage || canUserDeleteSlices) && !dragging && (
          <div className={styles.removeButtonContainer}>
            <Icon
              name="delete"
              size={toggled ? 16 : 24}
              onClick={handleDeleteMedicalImage}
            />
          </div>
        )}
      </div>

      {medicalImageLabel && (
        <p className={cn(styles.medicalImageLabel, 'p2')}>
          {medicalImageLabel}
        </p>
      )}

      {draggable && (
        <div className={styles.selectSliceControl}>
          <Checkbox
            boxClassName={styles.selectCheckbox}
            checked={isImgSelected || false}
            onChange={() => {
              if (toggleSelected) {
                toggleSelected(medicalImage);
              }
            }}
          />
        </div>
      )}

      {typeof order === 'number' && order >= 0 ? (
        <div
          className={cn(styles.numeration, isImgSelected && styles.selected)}
        >
          {isImgSelected && <Icon name="check" size={16} />}
          <span className={styles.order}>{order + 1}</span>
        </div>
      ) : null}

      <div className={styles.orientWrapper}>
        <div className={styles.orientContainer}>
          <span className={styles.medicalImageText}>
            {medicalImage?.leftSideOrient}
          </span>
          <span className={styles.medicalImageText}>
            {medicalImage?.rightSideOrient}
          </span>
        </div>
      </div>
    </div>
  );
};
