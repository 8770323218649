import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Accordion, WidgetCard } from '@/shared/ui';
import { useAppSelector } from '@/shared/hooks';
import { FeatureFlag } from '@/shared/api/protocol-ts/model/dto_organization_pb';
import { ReportType } from '@/shared/api/protocol-ts/model/dto_report_pb';

import { conditionModel } from '@/entities/condition';
import { organizationModel } from '@/entities/organization';

import { RarePathologies } from './RarePathologies/RarePathologies';
import styles from './NonDentalFindings.module.scss';

type NonDentalFindingsProps = {
  reportType: ReportType;
  className?: string;
};

export const NonDentalFindings: FC<NonDentalFindingsProps> = (props) => {
  const { className, reportType } = props;

  const hideNonDentalFindings = useAppSelector(
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_Hide_NonDentalFindings,
    ),
  );

  const maxFaxConditions = useAppSelector(
    conditionModel.selectors.selectMaxFax,
  );

  if (hideNonDentalFindings || maxFaxConditions?.length === 0) {
    return null;
  }

  return (
    <WidgetCard className={className}>
      <Accordion.Root type="single" collapsible defaultValue="one">
        <Accordion.Item value="one">
          <Accordion.Header>
            <Accordion.Trigger withRightButton>
              <h4 className="h4">
                <FormattedMessage
                  id="report.rarePathology.header"
                  defaultMessage="Non-dental findings"
                />
              </h4>
            </Accordion.Trigger>
          </Accordion.Header>

          <Accordion.Content className={styles.content}>
            <RarePathologies
              conditions={maxFaxConditions}
              reportType={reportType}
            />
          </Accordion.Content>
        </Accordion.Item>
      </Accordion.Root>
    </WidgetCard>
  );
};
