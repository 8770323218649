import { FC, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import { Condition } from '@/shared/api/protocol-ts/model/dto_report_condition_pb';
import { AbsoluteBlock } from '@/shared/graphics/RenderComponents/AbsoluteBlock';
import { RCImage } from '@/shared/graphics/RenderComponents/RCImage/RCImage';
import {
  Localization,
  RCLocalizations,
} from '@/shared/graphics/RenderComponents/RCLocalizations/RCLocalizations';
import { RCContainer } from '@/shared/graphics/RenderComponents/RCContainer/RCContainer';
import { getImageSrc } from '@/shared/lib';
import { ReportType } from '@/shared/api/protocol-ts/model/dto_report_pb';

import { conditionText } from '@/entities/condition/config/i18n';

import { ContextRequest } from 'graphics';

import styles from './RarePathologies.module.scss';

type NonDentalFindingsProps = {
  conditions: Condition[];
  reportType: ReportType;
  className?: string;
  isPreviewMode?: boolean;
};

export const RarePathologies: FC<NonDentalFindingsProps> = (props) => {
  const { className, conditions, reportType, isPreviewMode = false } = props;

  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const [hoveredConditionID, setHoveredConditionID] = useState('');

  const containerRef = useRef<HTMLDivElement>(null);

  const { formatMessage } = useIntl();

  const localizations: Localization[] = conditions.flatMap((condition) =>
    condition.Localizations.map(({ BBox }) => ({
      conditionID: condition.ID,
      xmin: BBox?.X?.Min ?? 0,
      ymin: BBox?.Y?.Min ?? 0,
      xmax: BBox?.X?.Max ?? 0,
      ymax: BBox?.Y?.Max ?? 0,
    })),
  );

  const handleConditionHover = (id: string) => {
    if (!isPreviewMode) {
      setHoveredConditionID(id);
    }
  };

  const imageSrc = {
    url: getImageSrc(
      conditions?.at(0)?.Localizations?.at(0)?.TargetAssetID,
      'original',
    ),
    kind: reportType === ReportType.ReportType_CBCT_GP ? 'dicom' : 'raster',
  };

  useEffect(() => {
    if (containerRef.current && imageSize.width > 0) {
      const aspect = containerRef.current.clientWidth / imageSize.width;
      const height = imageSize.height * aspect;

      setContainerHeight(height);
    }
  }, [containerRef.current, imageSize]);

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.conditions}>
        {conditions.map((condition, index) => {
          const isLastCondition = index === conditions.length - 1;

          return (
            <p
              key={condition.ID}
              className={cn(
                'p2',
                isPreviewMode && 'blackText',
                !isPreviewMode && styles.isInteractive,
              )}
              onMouseEnter={() => handleConditionHover(condition.ID)}
              onMouseLeave={() => handleConditionHover('')}
            >
              {formatMessage(conditionText[condition.Code])}

              {isPreviewMode && !isLastCondition && `, `}
            </p>
          );
        })}
      </div>

      <RCContainer
        ref={containerRef}
        className={styles.pano}
        style={{
          width: '100%',
          height: containerHeight,
          borderRadius: '16px',
          overflow: 'hidden',
        }}
      >
        <AbsoluteBlock>
          <RCImage
            src={imageSrc as ContextRequest}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
            onLoaded={(context) => {
              setImageSize({
                width: context.width,
                height: context.height,
              });
            }}
          />
        </AbsoluteBlock>

        <AbsoluteBlock>
          <RCLocalizations
            detections={localizations}
            hoveredConditionID={hoveredConditionID}
            imageSize={imageSize}
            isNonDentalFindings
          />
        </AbsoluteBlock>
      </RCContainer>
    </div>
  );
};
