import { FC, ReactNode, useEffect } from 'react';
import cn from 'classnames';
// import { useSearchParams } from 'react-router-dom';

import { RenderPreviewSettings } from '@/shared/config';
import { useAppDispatch } from '@/shared/hooks';

import { reportsModel } from '@/entities/reports';

import {
  MedicalImageRender,
  ViewMIR,
} from '../../../../shared/graphics/medicalImageRender/MedicalImageRender';

import styles from './PanowingsReportRender.module.scss';

type PanowingsReportRenderProps = {
  className?: string;
  children?: ReactNode;
  previewSettings?: RenderPreviewSettings;
};

export const PanowingsReportRender: FC<PanowingsReportRenderProps> = (
  props,
) => {
  const { className, children, previewSettings } = props;

  //TODO: implement focus mode viasearch params
  // const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useAppDispatch();

  useEffect(() => {
    MedicalImageRender.addEventListener('layout', (event) => {
      if (event.mode === 'focus') {
        dispatch(reportsModel.actions.setToolbarActiveControl('view'));
        dispatch(reportsModel.actions.setFocusedMetaImageID(event.id));
      }
    });
  }, []);

  return (
    <ViewMIR
      viewName="main"
      id="report_render"
      className={cn(
        styles.container,
        previewSettings?.isPreview ? styles.preview : '',
        className,
      )}
    >
      {children}
    </ViewMIR>
  );
};
