import { ToolNames } from '@/shared/config';
import { IconNames } from '@/shared/ui';

export const CONTROL_TOOLS_ICONS: Record<ToolNames, IconNames> = {
  ruler: 'ruler',
  arrow: 'arrow',
  angle: 'angle',
  eraser: 'erase',
  view: 'eyeFilled',
  brightness: 'brightness',
  expand: 'expand',
  invert: 'invert',
  reset: 'back',
  move: 'move',
  zoom: 'zoom',
  split: 'split',
  download: 'download',
  sharpness: 'sharpness',
  sharpening: 'sharpness',
  editNumbers: 'toothnum',
  ios: 'ios',
  hideMarks: 'close',
};
